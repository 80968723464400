import BaseService from './base-service';

const compsToPackages = {
  'wixui.RichTextBox': () => Promise.all([import(/* webpackChunkName: "wixui.RichTextBox" */ '../legacy/components/RichTextBox')]),
  'wixui.Slider': () => Promise.all([import(/* webpackChunkName: "wixui.Slider" */ '../components/Slider')]),
  'wixui.Pagination': () => Promise.all([import(/* webpackChunkName: "wixui.Pagination" */ '../components/Pagination')]),
  'wixui.AddressInput': () => Promise.all([import(/* webpackChunkName: "wixui.AddressInput" */ '../components/AddressInput')]),
  'wixui.StylableLine': () => Promise.all([import(/* webpackChunkName: "wixui.StylableLine" */ '../components/StylableLine')]),
  'wixui.RatingsInput': () => Promise.all([import(/* webpackChunkName: "wixui.RatingsInput" */ '../components/RatingsInput')]),
  'wixui.TimePicker': () => Promise.all([import(/* webpackChunkName: "wixui.TimePicker" */ '../components/TimePicker')]),
  'wixui.RatingsDisplay': () => Promise.all([import(/* webpackChunkName: "wixui.RatingsDisplay" */ '../components/RatingsDisplay')]),
  'wixui.VideoPlayer': () => Promise.all([import(/* webpackChunkName: "wixui.VideoPlayer" */ '../components/VideoPlayer')])
};

module.exports = new BaseService(compsToPackages);
